import React, { useEffect, useState } from "react";
import MessageBox from "../../Shared/MessageBox/MessageBox";
import { API_URL } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";

export default function Dashboard(){
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');
	const [dataset, setDataset] = useState('');
	const [error, setError] = useState('');
	const [llmModel, setLLMModel] = useState('');
	const [llmModelOptions, setLLMModelOptions] = useState([]);
	const tokenUpdated = localStorage.getItem('chattoken');
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{6,15}$/;
	const user = JSON.parse(localStorage.getItem('user'));
	const navigate = useNavigate();

	const datasetList = [
		{
			title: 'DocChat',
			value: 'own',
		},
		{
			title: 'SEBI DocChat',
			value: 'sebi',
		},
		{
			title: 'Drafting',
			value: 'contract',
		},
	];

	const llmModelList = [
		{
			title: 'GPT 3.5',
			value: 'gpt-3.5-turbo-0125',
		},
		{
			title: 'GPT 4',
			value: 'gpt-4-0125-preview',
		}
	]

	const draftingModeList = [
		{
			title: 'GPT 4',
			value: 'gpt-4-0125-preview'
		},
		{
			title: 'Claude 2.1',
			value: 'anthropic.claude-v2'
		}
	]

	const errorStyles = {
		backgroundColor: 'rgb(253, 236, 234)',
		color: 'red',
		padding: '10px 15px',
		borderRadius: '4px',
		marginTop: '10px',
	}
	
	useEffect(()=>{
		if(user?.email !== 'vishwam@webnyay.ai'){
			navigate('/not-found');
		}
	},[])

	useEffect(() => {
		if (dataset === 'contract') {
		  setLLMModelOptions(draftingModeList);
		} else {
		  setLLMModelOptions(llmModelList);
		}
	  }, [dataset]);

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
		setError('');
	}

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
		setError('');
	}

	function validatePassword(password) {
    if (!passwordRegex.test(password)) {
      return false
    }
    return true;
  }

	const handleNameChange = (e) => {
		const newName = e.target.value
		setName(newName);
	}

	const handleChangeUserType = (e) => {
		setDataset(e.target.value);
	}

	const handleChangeLLMModel = (e) => {
		setLLMModel(e.target.value);
	}

	const handleSubmit = (e) => {
    e.preventDefault();
    if(!validatePassword(password)){
      setError("Invalid password. Must be minimum of 6 characters including atleast one upper case alphabet, lower case alphabet, number and special character.")
      return;
    }
		const data = {
      email: email,
			username: email,
      full_name: name,
      password: password,
      dataset: dataset,
			llm_model: llmModel,
    }
    console.log(data,'data');
    fetch(`${API_URL}/users/add-new-user/`, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${tokenUpdated}`,
					'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      Cache: 'default'
    }).then((res)=>{
      return res.json();
    })
    .then((data) => {
			if(data?.[0] === 'User added successfully'){
				alert('User added successfully.');
				setEmail('');
				setDataset('');
				setName('');
				setPassword('');
			}
			else if(data === "User with this email already exists"){
				setError(data);
			}
    }).catch((err)=>{
      console.log(err);
    })
  };

	return (
		<div style={{display:'flex',padding:'10px'}}>
			<div style={{padding:'20px'}}>
				<IconButton color='primary' onClick={()=> navigate('/chat')} style={{padding:'12px'}}>
					<KeyboardBackspace/>
				</IconButton>
			</div>
			<div className="auth-form-container">
				<div className='main-container'>
					<div className='login-title'>Create User</div>
					<div style={{height: '52px'}}></div>
					<form className="auth-form" onSubmit={handleSubmit}>
						<div className="form-group">
							<label className='title-input' htmlFor="name">Name</label>
							<input type="text" id="name"  className='input-box' placeholder='Enter name' value={name} onChange={handleNameChange} required />
						</div>
						<div className="form-group">
							<label className='title-input' htmlFor="email">Email address</label>
							<input type="email" id="email"  className='input-box' placeholder='Myemail@mail.com' value={email} onChange={handleEmailChange} required/>
						</div>
						<div className="form-group">
							<label className='title-input' htmlFor="password">Password</label>
							<input type="password" id="password"  className='input-box' placeholder='At least 6 characters' value={password} onChange={handlePasswordChange} required />
						</div>
						<div className="form-group">
							<label className='title-input' htmlFor="user_type">User Type</label>
							<select name="User Type" id="user_type" className="input-box" style={{height:'50px'}} placeholder="Select platform" onChange={handleChangeUserType} required>
								<option value="">Select platform</option>
								{datasetList.map((item)=>{
									return (
										<option value={item.value}>{item.title}</option>
									)
								})}
							</select>
						</div>
						<div className="form-group">
							<label className='title-input' htmlFor="llm_model">LLM Model</label>
							<select name="LLM Model" id="llm_model" className="input-box" style={{height:'50px'}} placeholder="Select LLM Model" onChange={handleChangeLLMModel} required>
								<option value="">Select LLM Model</option>
								{llmModelOptions?.map((item)=>{
									return (
										<option value={item.value}>{item.title}</option>
									)
								})}
							</select>
						</div>
						{error && (
							<MessageBox 
								message={error}
								styles={errorStyles}
							/>
						)}
						<button type="submit" className="auth-button" style={{borderRadius:0,fontSize:'1rem'}}>Submit</button>
					</form>
				</div>
			</div>
		</div>
	)
}