import { useState, useEffect, useRef } from 'react';
import { Tooltip, DialogTitle, Dialog, DialogContent, DialogActions, Button, Typography, Rating, TextField, InputAdornment } from '@mui/material';
import './Chat.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../config/config';
import {ThreeDots} from 'react-loader-spinner';
import ReviewFormDialog from './ReviewFormDialog';
import { isSebi } from '../App';
import Arrow from '../images/Arrow.svg';
import Webnyay_favicon from '../images/Webnyay_favicon.svg';
import { Person, SmsFailedOutlined } from '@mui/icons-material';
import { getDataSetClient } from '../Utils/getDataSetClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Chat({onCommandSubmit, allChatData, spinner, user, filesUploaded, setPdfUrlForViewer, pdfUrlForViewer, setSourcePageNumber,  setShowPdfModal}) {
    const [command, setCommand] = useState('');
    const [text, setText] = useState('');
    const [source, setSource] = useState([])
    const [rateReviewModal, setRateReviewModal] = useState(false);

    const fullName = user?.name?.trim();
    var first_name = fullName?.substring(0, fullName?.lastIndexOf(' '));
    var last_name = fullName?.substring(fullName?.lastIndexOf(' ') + 1, fullName?.length);

    const initialRatings = {
      accuracy_rating: 0,
      helpful_rating: 0,
      source_rating: 0,
      length_rating: 0,
    }

    const [ratings, setRatings] = useState(initialRatings);
  
    const [comment, setComment] = useState('');
    const [messageIdForReview, setMessageIdForReview] = useState('')

    const [isCopiedStates, setIsCopiedStates] = useState([]); // An array to store isCopied state for each chat item

    const handleCopyClick = (result, index) => {
      navigator.clipboard.writeText(result);
  
      // Update the isCopied state for the specific chat item
      const newIsCopiedStates = [...isCopiedStates];
      newIsCopiedStates[index] = true;
      setIsCopiedStates(newIsCopiedStates);
  
      setTimeout(() => {
        // Reset the isCopied state for the specific chat item after 2 seconds
        const newIsCopiedStates = [...isCopiedStates];
        newIsCopiedStates[index] = false;
        setIsCopiedStates(newIsCopiedStates);
      }, 2000);
    };

    const messagesEndRef= useRef(null);
    const tokenUpdated = localStorage.getItem('chattoken');
    const navigate = useNavigate();

    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [command, allChatData]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if((!isSebi && (filesUploaded.length === 0 ) && getDataSetClient() !== 'contract')){
        alert('Please upload a document before start querying!');
        return;
      }
      setText(command);
      setCommand("");
      await onCommandSubmit(command);
    };

    const handleEnterInput = (e) => {
      setCommand(e.target.value);
      // setText(e.target.value);
    }

    const getFile = (name) => {
      return /[^/]*$/.exec(name)[0]
    }

    const handleSourceCitationsUrl = (url) => {
      setPdfUrlForViewer(url);
      setShowPdfModal(true)
    };

    const handleSourceCitations = (pageContent) => {
      // Open a new tab with the page content
      const newTab = window.open('', '_blank');
      
      // Write the content to the new tab
      newTab.document.write(`<html><body><div>${pageContent}</div></body></html>`);
      
      // Close the document to finish writing
      newTab.document.close();
    };

    function handleRateReviewModal(messageId){
      setMessageIdForReview(messageId)
      setRateReviewModal(!rateReviewModal);
    }

    const handleRatingChange = (newValue, type) => {
      // Update the state for the corresponding type of rating
      setRatings((prevRatings) => ({
        ...prevRatings,
        [type]: newValue || 0,
      }));
    };
    const handleCommentChange = (event) => {
      setComment(event.target.value);
    };

    const isAnyRatingFilled = (ratings) => {
      return Object.values(ratings).some((value) => value > 0);
    };

    const handleSubmitReview = (event, messageIdForReview) => {
      const CHAT_FEEDBACK = {
        ...ratings,
        message: messageIdForReview,
        comment: comment 
      }
      if(!isAnyRatingFilled(ratings) && comment.length == 0){
        alert('Please review and then submit')
        return;
      }
      fetch(`${API_URL}/chatbot/chatfeedback/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenUpdated}`,
        },
        body: JSON.stringify(CHAT_FEEDBACK),
        Cache: 'default'
      }).then(function(response) {
        return response.json();
        }).then((data) => {
          toast('Answer Reviewed Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            color: 'red'
          });
          setRateReviewModal(false);
          setComment('')
          setRatings(initialRatings)
      })
      .catch((err)=>{
        console.log(err);
      })
    }

      const processText = (text) => {
        const lines = text.split('\n');
    
        return lines.map((line, index) => {
            const parts = line.split(/(\*\*[^*]+\*\*)/g);
            return (
                <div key={index}>
                    {parts.map((part, partIndex) => {
                        if (part.startsWith('**') && part.endsWith('**')) {
                            return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
                        } else if (part.startsWith('- ')) {
                            return (
                                <span key={partIndex}>
                                    {part.slice(2)}
                                </span>
                            );
                        } else {
                            return <><span key={partIndex}>{part}</span><br/></>;
                        }
                    })}
                </div>
            );
        });
    };  

    const extractNumber = (input) => {
      const regex = /\((\d+)\)/;
      const match = input.match(regex);
      if (match) {
        return match[1];
      }
      return null;
    };
     
    return (
      <div className={`flex flex-col ${pdfUrlForViewer === '' ? 'w-full' : 'w-6/12'}`}>
        <div style={{height: '3rem', borderBottom: '1px solid #e5e5e5'}}></div>
        <div className='sebiChatWrapIndividual'>
          {allChatData?.map((item, index) => {
            return(
              <div key={index}>
                <div className='messageContainer'>
                  <div style={{width:'48px'}}></div>
                  <div className='query' style={!isSebi ? {background: '#4A3AFF'} : null}>
                    {item?.content}
                  </div>
                  <div className='avtar' style={isSebi ? {background: '#040415'} : {background: '#4A3AFF'}}>
                    <Person style={{color:'white'}}/>
                  </div>
                </div>
                <div className='messageContainer'>
                  <div className='avtar'>
                    <img src={Webnyay_favicon} alt='webnyay' width={'30px'}/>
                  </div>
                  <div className='response'>
                    {processText(item?.result)}
                    {getDataSetClient() !== 'contract' && 
                      <div className='gptSource'>
                        Source Citations - {
                          item?.source_citation_urls?.length > 0 ? (
                            item?.source_citation_urls?.map((source, index) => 
                              <Tooltip onClick={() => {handleSourceCitationsUrl(source.url);setSourcePageNumber(extractNumber(source?.file))}} placement="top" title={'Click to open'}>{'['+source.file+'],'}</Tooltip>
                            )
                          ) : (
                            item?.source_data?.map((sourceNumber, index) => 
                              <Tooltip onClick={() => handleSourceCitations(sourceNumber.page_content)} placement="top" title={'Click to open in new tab'}>{'['+sourceNumber.id+' - '+getFile(sourceNumber.source_file)+']'}</Tooltip>
                            )
                          )
                        }
                      </div>
                    }
                  </div>
                  <div style={{width:'48px'}}>
                    <div className='options'>
                      <div onClick={() => handleCopyClick(item.result, index)}>
                        <ContentCopyIcon sx={{fontSize: '1rem', cursor:'pointer'}} />
                      </div>
                      {isCopiedStates[index] && <div className="copiedMessage" >Copied!</div>}
                      <div onClick={() => handleRateReviewModal(item?.id)}><SmsFailedOutlined sx={{fontSize: '1rem', cursor:'pointer'}}/></div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
           <div ref={messagesEndRef} />
          {text && spinner ?
            <div className='messageContainer'>
              <div style={{width:'48px'}}></div>
              <div className='query' style={!isSebi?{background:'#4A3AFF'}:null}>
                {text}
              </div>
              <div className='avtar' style={isSebi?{background: '#040415'}:{background:'#4A3AFF'}}>
                <Person style={{color:'white'}}/>
              </div>
            </div>
            : null
          }
          {spinner ? 
            <div className='loaderWrapperNew'>
              <ThreeDots 
                height="80" 
                width="80" 
                radius="9"
                color="#4A3AFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
           : null
        }
        </div>
        <div className="chatwrapper">
            <form onSubmit={handleSubmit} className="chatwrapper">
              <TextField
                className='chatInputBox'
                type="text"
                placeholder="Ask Webnyay..."
                value={command}
                onChange={(e) => handleEnterInput(e)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset, &:hover fieldset": {
                      borderColor: "#c5c5c5",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><img src={Arrow} alt="arrow" width={'45px'} onClick={handleSubmit} style={{cursor:'pointer'}}/></InputAdornment>,
                  style: {
                    borderRadius: "22px",
                    paddingRight: '8px',
                    fontSize: '14px',
                  }
                }}
              />
            </form>
        </div>
        <ToastContainer/>
        {rateReviewModal ?
          <ReviewFormDialog
            open={rateReviewModal}
            handleClose={handleRateReviewModal}
            handleRatingChange={handleRatingChange}
            handleCommentChange={handleCommentChange}
            handleSubmitReview={handleSubmitReview}
            ratings={ratings}
            comment={comment}
            messageIdForReview={messageIdForReview}
          />
         : null
        }
      </div>
    );
  }
  
  export default Chat;
  