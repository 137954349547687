import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import ChatPDf from '../ChatPdf';
import { isSebi } from '../App';
import { isLoggedIn } from '../Utils/ChatToken';

const PrivateRoute = () => {
    const auth = isLoggedIn() === '' ? false : true; 

    const route = isSebi ? '/sebi/login' : '/';
    
    return auth ? <ChatPDf /> : <Navigate to={route}/>;
}

export default PrivateRoute;