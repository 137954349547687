import React, { useState, useEffect } from 'react';
import { json, useNavigate } from "react-router-dom";
import { API_URL } from '../config/config';
import './AuthForm.css';
import { KeyboardBackspace, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import OTPInput from 'react-otp-input';
import Graphics from './Graphics';
import WebLogo from '../images/WebLogo.png';
import MessageBox from '../Shared/MessageBox/MessageBox';
import { isSebi } from '../App';
import SebiGraphics from './SebiGraphic/SebiGraphic';
import { Alert, Checkbox, CircularProgress, FormControlLabel, InputAdornment, TextField, Typography } from '@mui/material';
import EnvelopeSimple from '../images/EnvelopeSimple.svg';
import Lock from '../images/Lock.svg';
import User from '../images/User.svg';
import Webnyay_favicon from '../images/Webnyay_favicon.svg';
import DocChat_Black from '../images/DocChat_Black.svg';
import { registerSubmission, sendOTP } from '../apis';

const errorStyles = {
  backgroundColor: 'rgb(253, 236, 234)',
  color: 'red',
  padding: '10px 15px',
  borderRadius: '4px',
  marginTop: '10px',
}


const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [name, setName] = useState('')
  const [page, setPage] = useState('register');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);
  const [timmerRunning, setTimmerRunning] = useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{6,15}$/;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [passwordType, setPasswordType] = useState('password');
  const [confirmpasswordType, setConfirmpasswordType] = useState('password');
  const [existingUser, setExistingUser] = useState(true);
  const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    function handleLoginClick() {
        navigate(isSebi?"/sebi/login":"/");
    }

  const otp_inputStyle = {
    padding: '1.2rem',
    borderRadius: 0,
    border: '1px solid #c9caca',
    backgroundColor: 'transparent',
    "&:hover": {
      borderColor: 'black',
      borderRadius: 0,
    },
    "&:focus": {
      outline: 'none',
      border: '1px solid #4A3BFF',
      borderRadius: 0,
    }
  }

  useEffect(()=>{
    if(timmerRunning){
      setMinutes(4);
      setSeconds(59);
    }
  },[timmerRunning]);

  useEffect(() => {
    if(!timmerRunning) return;
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
  
      if (seconds === 0) {
        if (minutes === 0) {
          setTimmerRunning(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [seconds,timmerRunning]);

  const validateEmail = (email) => {
    return email.match(emailRegex);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setUsername(value);
    setError('');
  };

  const handleBlurOnEmail = (e) => {
    const value = e.target.value;
    if(validateEmail(value)){
      setLoading(true);
      fetch(`${API_URL}/users/user-exists/?email=${value}&dataset=${isSebi?'sebi':'own'}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
      }).then((res)=>{
        return res.json();
      })
      .then((res) => {
        setLoading(false);
        console.log(res);
        if(res){
          const platform = isSebi ? 'sebi' : 'own';
          const isRegistered = res?.dataset.includes(platform);
          if(isRegistered){
            setError('User already registered!');
          }
          setExistingUser({
            name: res.name,
            dataset: res.dataset,
            isRegistered: isRegistered,
          });
        }
        else{
          setExistingUser(res);
        }
      }).catch((err)=>{
        console.log(err);
      })
    }
  }

  const handleUsernamechange = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError('');
  };

  // const handleFirstName = (e) => {
  //   setFirstname(e.target.value);
  // };

  // const handleLastName = (e) => {
  //   setLastname(e.target.value);
  // };

  const handleNameChange = (e) => {
    const newName = e.target.value
    setName(newName);
    // const {firstNameSeparated, lastNameSeparated} = extractFirstLastName(newName);
    // setFirstname(firstNameSeparated)
    // setLastname(lastNameSeparated)
  }

  // function extractFirstLastName(fullName){
  //   const lastSpaceIndex = fullName.lastIndexOf(' ');

  //   if (lastSpaceIndex !== -1) {
  //     const firstNameSeparated = fullName.slice(0, lastSpaceIndex);
  //     const lastNameSeparated = fullName.slice(lastSpaceIndex + 1);
  //     return { firstNameSeparated, lastNameSeparated };
  //   } else {
  //     // Handle cases where there is no space in the full name
  //     // You might want to provide a default behavior or error handling
  //     return { firstNameSeparated: fullName, lastNameSeparated: '' };
  //   }
  // };

  const getOTP = (data) => {
    sendOTP(data)
    .then((res) => {
      if (res?.success === 'Otp sent') {
        setPage('verifyEmail');
        setTimmerRunning(true);
        setOtp('');
      } else if (res?.error) {
        alert(res?.error);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  function validatePassword(password) {
    if (!passwordRegex.test(password)) {
      return false
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(email === '' || name === '' || password === ''){
      return;
    }
    if(password !== confirmpassword){
      setError('Password must match');
      return;
    }
    if(!validatePassword(password)){
      setError("Invalid password. Must be minimum of 6 characters including atleast one upper case alphabet, lower case alphabet, number and special character.")
      return;
    }else{
      const data = {
        "email": email,
        "name": name,
      }
      getOTP(data);
    }
  };

  const sendOTPRegisteredUser = (e) => {
    e.preventDefault();
    const data = {
      "email": email,
      "name": existingUser?.name,
    };
    getOTP(data);
  };

  const handleSubmitOtp = () => {
    const data = {
      email: email,
      username: username,
      full_name: name,
      password: password,
      confirm_password: confirmpassword,
      otp: otp,
      dataset: isSebi ? 'sebi' : 'own',
    }
    console.log(data,'data')
    registerSubmission(data)
      .then((data) => {
        if (data?.id) {
          navigate(isSebi ? '/sebi/login' : '/');
        } else if (data?.status === '400') {
          alert(data?.data?.error);
        }
      })
    .catch((err) => {
      console.log(err);
    });
  }

  const handleSubmitOtpExistingUser = () => {
    const data = {
      email: email,
      otp: otp,
      dataset: isSebi ? 'sebi' : 'own',
    }
    console.log(data,'data')
    fetch(`${API_URL}/users/verify-user/`, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      Cache: 'default'
    }).then((res)=>{
      return res.json();
    })
    .then((data) => {
      console.log(data)
      if(data?.id){
        navigate(isSebi?'/sebi/login':'/');
      }
      else if(data?.status === '400'){
        alert(data?.data?.error);
      }
      else if(data?.error){
        alert(data?.error);
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  const handleResendOtp = () => {
    const data = {
      "email": email,
      "name": `${firstname} ${lastname}`
      // username: username,
    }
    getOTP(data);
  }

  const handleClickBack = () => {
    setPage('register');
    setTimmerRunning(false);
  }

  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toogleConfirmPasswordType = () => {
    if (confirmpasswordType === "password") {
      setConfirmpasswordType("text");
    } else {
      setConfirmpasswordType("password");
    }
  };


  return (
    page === 'register' ? (
      <div className='auth-form-divider'>
        <div className={isSebi?'sebi-graphic':'graphics'}>
          {isSebi ? <SebiGraphics/> : <Graphics/>}
        </div>
        <div className="auth-form-container">
          <div className='main-container'>
            <div className='weblogoImg'>
              {isSebi ? (
                <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
              ) : (
                <>
                  <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
                  <div style={{height:'0.7rem'}}></div>
                  <img src={DocChat_Black} width={'220px'} height={'100%'}/>
                </>
              )}
            </div>
            <div className='login-title'>Register</div>
            <div className='login-subtitle'>Create an account to continue!</div>
            <div style={{height: '52px'}}></div>
            <form className="auth-form" onSubmit={(existingUser?.name && !existingUser?.isRegistered) ? sendOTPRegisteredUser : handleSubmit}>
              <div className="form-group">
                <label className='title-input' htmlFor="email">Email</label>
                <TextField
                  required
                  variant='outlined'
                  id='email'
                  type='email'
                  placeholder='Eg - myemail@mail.com'
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleBlurOnEmail}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><img src={EnvelopeSimple} alt="Envelope" /></InputAdornment>,
                    endAdornment:  <InputAdornment position='end'><CircularProgress style={loading?{display:'flex',width:'18px',height:'18px'}:{display:'none'}}/></InputAdornment>
                  }}
                />
              </div>
              {!existingUser && (
                <>
                  <div className="form-group">           
                    <label className='title-input' htmlFor="name">Name</label>
                    <TextField
                      required
                      variant='outlined'
                      id='name'
                      placeholder='Enter name'
                      value={name}
                      onChange={handleNameChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={User} alt="User" /></InputAdornment>
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className='title-input' htmlFor="password">Password</label>
                    <TextField
                      id='password'
                      type={passwordType}
                      placeholder='At least 6 characters'
                      value={password}
                      onChange={handlePasswordChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>,
                        endAdornment: <InputAdornment position='end' onClick={tooglePasswordType} style={{ cursor: "pointer", color: '#5a5a5a' }}>
                          {passwordType === "password" ? (
                            <VisibilityOffOutlined fontSize='small' />
                          ) : (
                            <VisibilityOutlined fontSize='small' />
                          )}
                        </InputAdornment>
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label className='title-input' htmlFor="confirmpassword">Confirm Password</label>
                    <TextField
                      id='confirmpassword'
                      type={confirmpasswordType}
                      placeholder='At least 6 characters'
                      value={confirmpassword}
                      onChange={handleConfirmPasswordChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>,
                        endAdornment: <InputAdornment position='end' onClick={toogleConfirmPasswordType} style={{ cursor: "pointer", color: '#5a5a5a' }}>
                          {confirmpasswordType === "password" ? (
                            <VisibilityOffOutlined fontSize='small'/>
                          ) : (
                            <VisibilityOutlined fontSize='small'/>
                          )}
                        </InputAdornment>
                      }}
                    />
                  </div>
                </>
              )}
              {(existingUser?.name && !existingUser?.isRegistered) && (
                <Alert severity='info'>You are already registered in our system. Please verify the OTP to continue.</Alert>
              )}
              {error && (
                <MessageBox 
                  message={error}
                  styles={errorStyles}
                />
              )}
              <div className='flex mt-2.5'>
                <div>
                  <Checkbox required />
                </div>
                <div>
                  <p className='text-left text-sm mt-1 text-gray-500'>
                    {'By using this product, you confirm that you have read and understood the '} 
                    <a href='/disclaimer-and-disclosure' target='_blank' className='text-blue-700 cursor-pointer underline'>Disclaimer and Disclosure</a>
                    {' terms'}
                  </p>
                </div>
              </div>
              <button type="submit" className="auth-button" style={{borderRadius:0,fontSize:'1rem'}} disabled={existingUser?.isRegistered}>{(existingUser?.name && !existingUser?.isRegistered) ? 'Send OTP' : 'Register'}</button>
            </form>
            <div className='auth-change'>
              Already have an account? <span className='auth-change-btn-change' onClick={handleLoginClick}>Login</span>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className='auth-form-divider'>
        <div className={isSebi?'sebi-graphic':'graphics'}>
          {isSebi ? <SebiGraphics/> : <Graphics/>}
        </div>
        <div className="auth-form-container">
          <div className='main-container'>
            <div className='weblogoImg'>
              {isSebi ? (
                <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
              ) : (
                <>
                  <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
                  <div style={{height:'0.7rem'}}></div>
                  <img src={DocChat_Black} width={'220px'} height={'100%'}/>
                </>
              )}
            </div>
            <div className='login-title'>Verify email address</div>
            <div className='login-subtitle'>Please enter the OTP we've send to</div>
            <div className='login-subtitle' style={{color: 'black', fontFamily: 'Satoshi-Medium', margin: 0}}>{email}</div>
            <div style={{height: '52px'}}></div>
            {/* <form className="auth-form" onSubmit={handleSubmitOtp}> */}
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                containerStyle={{justifyContent:'center',gap:'1.5rem'}}
                inputStyle={'otp-input-style'}
                renderInput={(props) => <input {...props} />}
              />
              <button type="submit" className="auth-button" style={{borderRadius:0,fontSize:'1rem'}} disabled={!otp} onClick={existingUser?.name ? handleSubmitOtpExistingUser : handleSubmitOtp}>Verify</button>
              <div className='auth-change'>
                Didn’t receive the OTP? 
                {seconds > 0 || minutes > 0 ? (
                  <span style={{color: 'red', marginLeft: '0.5rem'}}>
                    Please wait: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                ) : (
                  <span className='auth-change-btn-change' onClick={handleResendOtp} style={{marginLeft: '0.5rem'}}>Click to resend</span>
                )}
              </div>
              <div style={{textAlign:'center'}}>
                <span className='back-button' onClick={handleClickBack}><KeyboardBackspace style={{verticalAlign:'middle'}}/> Back</span>
              </div>
            {/* </form> */}
          </div>
        </div>
      </div>
    )

  );
};

export default Register;